import { getAppConfig } from "@services/appConfig"
import Logger from "@services/logger"

export const getTrackedLink = (urlString: string): string => {
    const { tenantConfig } = getAppConfig()
    const utmParams = tenantConfig.utmParams
    if (utmParams) {
        try {
            const url = new URL(urlString)
            url.searchParams.set("utm_source", utmParams.source)
            url.searchParams.set("utm_medium", utmParams.medium)
            url.searchParams.set("utm_campaign", utmParams.campaign)
            const trackedUrl = url.toString()
            return trackedUrl
        } catch (error) {
            Logger.error(error)
        }
    }
    return urlString // fallback
}
